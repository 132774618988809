<template>
  <div class="login-container">
    <span class="succ">开卡成功,<strong>3</strong>秒后关闭本页面</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      successurl: require("@/assets/success.jpg"),
    };
  },
  created() {
    setTimeout(() => {
      this.wx.closeWindow();
    }, 3000);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .succ {
    display: block;
    font-size: 30px;
    color: rgb(23, 207, 23);
    font-weight: bold;
    font-style: oblique;
    text-shadow: 0 1px hsl(0, 0%, 85%), 0 2px hsl(0, 0%, 80%),
      0 3px hsl(0, 0%, 75%), 0 4px hsl(0, 0%, 70%), 0 5px hsl(0, 0%, 65%),
      0 5px 10px black;
    font-size: 30px;
    font-family: "Microsoft YaHei";
    margin-top: 350px;
  }
}
</style>
